import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import has from 'lodash/has';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';
import includes from 'lodash/includes';
import checkSchema, { checkFormat, getProjectionsTo } from './checkSchema';

const typesToCheck = ['number', 'integer', 'string', 'null'];

function cleanTypes(types, value, clean) {
  const n = types.length;
  for (let i = 0; i < n; i += 1) {
    const error = checkSchema(
      {
        type: types[i],
      },
      value,
    );
    if (!error) {
      return value;
    }
  }
  for (let i = 0; i < typesToCheck.length; i += 1) {
    const type = typesToCheck[i];
    if (includes(types, type)) {
      const schema = {
        type,
      };
      const cleaned = clean(schema, value);
      const error = checkSchema(schema, cleaned);
      if (!error) {
        return cleaned;
      }
    }
  }
  return value;
}

function cleanValue(valueSchema, value) {
  let cleaned = value;
  if (isPlainObject(valueSchema)) {
    if (has(valueSchema, 'const')) {
      cleaned = valueSchema.const;
    }
    if (isArray(valueSchema.type)) {
      cleaned = cleanTypes(valueSchema.type, cleaned, cleanValue);
    }
    if (typeof valueSchema.type === 'string') {
      switch (valueSchema.type) {
        case 'null': {
          cleaned = null;
          break;
        }
        case 'string': {
          if (typeof cleaned !== 'string') {
            if (!isNil(cleaned) && typeof cleaned.toString === 'function') {
              cleaned = cleaned.toString();
            }
          }
          break;
        }
        case 'integer':
        case 'number': {
          if (typeof cleaned === 'string') {
            const number = +cleaned;
            if (!isNaN(number)) {
              cleaned = number;
            }
          }
          break;
        }
        case 'object': {
          if (isPlainObject(cleaned)) {
            if (isPlainObject(valueSchema.properties)) {
              cleaned = mapValues(cleaned, (v, k) =>
                cleanValue(
                  valueSchema.properties[k] || valueSchema.additionalProperties,
                  v,
                ),
              );
            }
            if (!valueSchema.additionalProperties) {
              if (valueSchema.properties) {
                cleaned = omitBy(cleaned, (v, k) => !valueSchema.properties[k]);
              } else {
                cleaned = {};
              }
            }
          }
          break;
        }
        case 'array': {
          if (isArray(cleaned)) {
            cleaned = map(cleaned, (v) => cleanValue(valueSchema.items, v));
          }
          break;
        }
        default:
        // do nothing
      }
    }
    if (valueSchema.type === 'string' && typeof cleaned === 'string') {
      if (
        typeof valueSchema.maxLength === 'number' &&
        value.length > valueSchema.maxLength
      ) {
        cleaned = cleaned.substr(0, valueSchema.maxLength);
      }
    }
    if (valueSchema.format && typeof cleaned === 'string') {
      let error = checkFormat(valueSchema.format, cleaned);
      if (error) {
        const projections = getProjectionsTo(valueSchema.format);
        const n = projections.length;
        for (let i = 0; i < n && error; i += 1) {
          const { format, isSubType, projection } = projections[i];
          if (!isSubType) {
            // NOTE: It does not make sense to check formats for a sub type
            //       because the check will fail by definition.
            error = checkFormat(format, cleaned);
            if (!error) {
              cleaned = projection(cleaned);
            }
          }
        }
      }
    }
    if (valueSchema.type === 'array' && isArray(cleaned)) {
      if (
        typeof valueSchema.maxItems === 'number' &&
        value.length > valueSchema.maxItems
      ) {
        cleaned = cleaned.slice(0, valueSchema.maxItems);
      }
    }
    if (has(valueSchema, 'allOf') && isArray(valueSchema.allOf)) {
      const n = valueSchema.allOf.length;
      for (let i = 0; i < n; i += 1) {
        cleaned = cleanValue(valueSchema.allOf[i], cleaned);
      }
    }
    // NOTE: This algorithm is invalid. Counterexample:
    //       { anyOf: [{ const: 'A' }, { const: 'B' }] }
    //       would result in setting value to "A" (always).
    // if (has(valueSchema, 'anyOf') && isArray(valueSchema.anyOf)) {
    //   cleaned = cleanFirstSuccessful(valueSchema.anyOf, cleaned);
    // }
    // if (has(valueSchema, 'oneOf') && isArray(valueSchema.oneOf)) {
    //   cleaned = cleanFirstSuccessful(valueSchema.oneOf, cleaned);
    // }
    if (has(valueSchema, 'if')) {
      const error = checkSchema(valueSchema.if, cleaned);
      if (!error && has(valueSchema, 'then')) {
        cleaned = cleanValue(valueSchema.then, cleaned);
      }
      if (error && has(valueSchema, 'else')) {
        cleaned = cleanValue(valueSchema.else, cleaned);
      }
    }
  }
  return cleaned;
}

export default cleanValue;
