import Id from '../../schemata/Id';

// INITIAL is a formal state, and I don't currently see any practical uses for it.
export const ACTIVITY_STATE__INITIAL = 'INITIAL';
// We predict that the activity will eventually take place, but we don't
// want it to be started yet. There may be at least two reasons for that:
// 1. We either don't know proper dateStart, e.g. this can be a post surgery milestone, but the surgery has not happened yet.
// 2. We do know dateStart, but we want the transition to ACTIVE not to happen unless some other condition is met.
export const ACTIVITY_STATE__PLANNED = 'PLANNED';
// The milestone was omitted due to end date in the past, but it is not CANCELLED yet,
// so it can potentially be rescheduled again.
export const ACTIVITY_STATE__OMITTED = 'OMITTED';
// The activity has well defined start/ end dates. Because this information
// is present, we are now able to send time based automatic notifications and
// we will automatically transition to ACTIVE when the time is right.
export const ACTIVITY_STATE__SCHEDULED = 'SCHEDULED';
// We've reached the activity start date, and so it's active. All answers sheets
// should be created at this stage and the patient/ doctor can start filling forms.
export const ACTIVITY_STATE__ACTIVE = 'ACTIVE';
// The activity was paused for some reason, e.g. the entire participation was suspended.
// A SUSPENDED activity will not expire when the dateEnd is reached. Instead it will wait
// for "resume" action, which should come along with a new value for dateEnd.
export const ACTIVITY_STATE__SUSPENDED = 'SUSPENDED';
// The activity reached its due date and some questionnaires were not completed.
export const ACTIVITY_STATE__EXPIRED = 'EXPIRED';
// The activity was planned before, but it never got scheduled, e.g. because some conditions were not met.
export const ACTIVITY_STATE__CANCELED = 'CANCELED';
// The activity was active at some point, but because of some problems it couldn't be
// completed. For example, patient did not signed the consent form.
export const ACTIVITY_STATE__ABORTED = 'ABORTED';
// The activity was considered completed. Note that it's not necessarily equivalent
// to all answers sheets being completed, because some of them might be optional.
export const ACTIVITY_STATE__COMPLETED = 'COMPLETED';

export const ACTIVITY_STATES = [
  ACTIVITY_STATE__INITIAL,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__SUSPENDED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__CANCELED,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__COMPLETED,
];

export const ACTIVITY_STATES_BEFORE_START = [
  ACTIVITY_STATE__INITIAL,
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__CANCELED,
];

export const ACTIVITY_STATES_AFTER_START = [
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__SUSPENDED,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__COMPLETED,
];

export const ACTIVITY_ACTION__PLAN = 'plan';
export const ACTIVITY_ACTION__CONFIGURE = 'configure';
export const ACTIVITY_ACTION__OMIT = 'omit';
export const ACTIVITY_ACTION__RESTORE = 'restore';
export const ACTIVITY_ACTION__START = 'start';
export const ACTIVITY_ACTION__SCHEDULE = 'schedule';
export const ACTIVITY_ACTION__DEFER = 'defer';
export const ACTIVITY_ACTION__CANCEL = 'cancel';
export const ACTIVITY_ACTION__ABORT = 'abort';
export const ACTIVITY_ACTION__COMPLETE = 'complete';
export const ACTIVITY_ACTION__COMPLETE_STEP = 'complete_step';
export const ACTIVITY_ACTION__RESCHEDULE = 'reschedule';
export const ACTIVITY_ACTION__SUSPEND = 'suspend';
export const ACTIVITY_ACTION__RESUME = 'resume';
export const ACTIVITY_ACTION__EXPIRE = 'expire';
export const ACTIVITY_ACTION__RESTART = 'restart';

export const ACTIVITY_ACTIONS = [
  ACTIVITY_ACTION__PLAN,
  ACTIVITY_ACTION__CONFIGURE,
  ACTIVITY_ACTION__OMIT,
  ACTIVITY_ACTION__RESTORE,
  ACTIVITY_ACTION__START,
  ACTIVITY_ACTION__SCHEDULE,
  ACTIVITY_ACTION__DEFER,
  ACTIVITY_ACTION__CANCEL,
  ACTIVITY_ACTION__ABORT,
  ACTIVITY_ACTION__COMPLETE,
  ACTIVITY_ACTION__COMPLETE_STEP,
  ACTIVITY_ACTION__RESCHEDULE,
  ACTIVITY_ACTION__SUSPEND,
  ACTIVITY_ACTION__RESUME,
  ACTIVITY_ACTION__EXPIRE,
  ACTIVITY_ACTION__RESTART,
];

const SCHEDULE_PROPERTIES = ['dateStart', 'dateEnd', 'timeStart', 'timeEnd'];

const COMPLETION_PROGRESS = ['completedSteps'];

export const ACTIVITY_STATE_MACHINE = {
  modelName: 'Activity',
  coordinates: [
    {
      name: 'state',
      type: 'string',
    },
    {
      name: 'milestoneId',
      type: 'string',
      pattern: Id.pattern,
    },
    {
      name: 'dateStart',
      type: 'string',
      format: 'full-date',
    },
    {
      name: 'dateEnd',
      type: 'string',
      format: 'full-date',
    },
    {
      name: 'timeStart',
      type: 'string',
      format: 'time',
    },
    {
      name: 'timeEnd',
      type: 'string',
      format: 'time',
    },
    {
      name: 'completedSteps',
      type: 'array',
      items: {
        type: 'string',
      },
    },
  ],
  states: [
    {
      state: ACTIVITY_STATE__INITIAL,
    },
    {
      state: ACTIVITY_STATE__PLANNED,
    },
    {
      state: ACTIVITY_STATE__OMITTED,
    },
    {
      state: ACTIVITY_STATE__SCHEDULED,
    },
    {
      state: ACTIVITY_STATE__ACTIVE,
    },
    {
      state: ACTIVITY_STATE__SUSPENDED,
    },
    {
      state: ACTIVITY_STATE__EXPIRED,
    },
    {
      state: ACTIVITY_STATE__CANCELED,
    },
    {
      state: ACTIVITY_STATE__ABORTED,
    },
    {
      state: ACTIVITY_STATE__COMPLETED,
    },
  ],
  transitions: [
    // INITIAL -> PLANNED (initialize)
    // INITIAL -> SCHEDULED (schedule)
    // INITIAL -> ACTIVE (start)
    // INITIAL -> OMITTED (omit)
    {
      from: ACTIVITY_STATE__INITIAL,
      to: ACTIVITY_STATE__PLANNED,
      actionType: ACTIVITY_ACTION__PLAN,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__INITIAL,
      to: ACTIVITY_STATE__ACTIVE,
      actionType: ACTIVITY_ACTION__START,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__INITIAL,
      to: ACTIVITY_STATE__SCHEDULED,
      actionType: ACTIVITY_ACTION__SCHEDULE,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__INITIAL,
      to: ACTIVITY_STATE__OMITTED,
      actionType: ACTIVITY_ACTION__OMIT,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    // PLANNED -> PLANNED (configure)
    // PLANNED -> SCHEDULED (schedule)
    // PLANNED -> ACTIVE (start)
    // PLANNED -> CANCELED (cancel)
    {
      from: ACTIVITY_STATE__PLANNED,
      to: ACTIVITY_STATE__PLANNED,
      actionType: ACTIVITY_ACTION__CONFIGURE,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__PLANNED,
      to: ACTIVITY_STATE__SCHEDULED,
      actionType: ACTIVITY_ACTION__SCHEDULE,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__PLANNED,
      to: ACTIVITY_STATE__ACTIVE,
      actionType: ACTIVITY_ACTION__START,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__PLANNED,
      to: ACTIVITY_STATE__CANCELED,
      actionType: ACTIVITY_ACTION__CANCEL,
    },
    // OMITTED -> SCHEDULED (restore)
    // OMITTED -> ACTIVE (start)
    // OMITTED -> CANCELED (cancel)
    {
      from: ACTIVITY_STATE__OMITTED,
      to: ACTIVITY_STATE__SCHEDULED,
      actionType: ACTIVITY_ACTION__RESTORE,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__OMITTED,
      to: ACTIVITY_STATE__ACTIVE,
      actionType: ACTIVITY_ACTION__START,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__OMITTED,
      to: ACTIVITY_STATE__CANCELED,
      actionType: ACTIVITY_ACTION__CANCEL,
    },
    // SCHEDULED -> SCHEDULED (reschedule)
    // SCHEDULED -> ACTIVE (start)
    // SCHEDULED -> CANCELED (cancel)
    // SCHEDULED -> OMITTED (omit)
    // SCHEDULED -> PLANNED (defer)
    {
      from: ACTIVITY_STATE__SCHEDULED,
      to: ACTIVITY_STATE__SCHEDULED,
      actionType: ACTIVITY_ACTION__RESCHEDULE,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__SCHEDULED,
      to: ACTIVITY_STATE__ACTIVE,
      actionType: ACTIVITY_ACTION__START,
      payload: [...SCHEDULE_PROPERTIES, 'milestoneId'],
    },
    {
      from: ACTIVITY_STATE__SCHEDULED,
      to: ACTIVITY_STATE__CANCELED,
      actionType: ACTIVITY_ACTION__CANCEL,
    },
    {
      from: ACTIVITY_STATE__SCHEDULED,
      to: ACTIVITY_STATE__OMITTED,
      actionType: ACTIVITY_ACTION__OMIT,
    },
    {
      from: ACTIVITY_STATE__SCHEDULED,
      to: ACTIVITY_STATE__PLANNED,
      actionType: ACTIVITY_ACTION__DEFER,
    },
    // ACTIVE -> ACTIVE (reschedule)
    // ACTIVE -> ACTIVE (active_step)
    // ACTIVE -> SUSPENDED (suspend)
    // ACTIVE -> ABORTED (abort)
    // ACTIVE -> COMPLETED (finish)
    // ACTIVE -> EXPIRED (time_out)
    {
      from: ACTIVITY_STATE__ACTIVE,
      to: ACTIVITY_STATE__ACTIVE,
      actionType: ACTIVITY_ACTION__RESCHEDULE,
      // NOTE: We could theoretically allow changing start date as well,
      //       but it wouldn't have any effect once the activity is already started.
      payload: ['dateEnd', 'timeEnd'],
    },
    {
      from: ACTIVITY_STATE__ACTIVE,
      to: ACTIVITY_STATE__ACTIVE,
      actionType: ACTIVITY_ACTION__COMPLETE_STEP,
      payload: COMPLETION_PROGRESS,
    },
    {
      from: ACTIVITY_STATE__ACTIVE,
      to: ACTIVITY_STATE__SUSPENDED,
      actionType: ACTIVITY_ACTION__SUSPEND,
    },
    {
      from: ACTIVITY_STATE__ACTIVE,
      to: ACTIVITY_STATE__ABORTED,
      actionType: ACTIVITY_ACTION__ABORT,
    },
    {
      from: ACTIVITY_STATE__ACTIVE,
      to: ACTIVITY_STATE__COMPLETED,
      actionType: ACTIVITY_ACTION__COMPLETE,
      payload: COMPLETION_PROGRESS,
    },
    {
      from: ACTIVITY_STATE__ACTIVE,
      to: ACTIVITY_STATE__EXPIRED,
      actionType: ACTIVITY_ACTION__EXPIRE,
    },
    // SUSPENDED -> SUSPENDED (complete_step)
    // SUSPENDED -> ACTIVE (resume)
    // SUSPENDED -> ABORTED (abort)
    // SUSPENDED -> COMPLETED (complete)
    {
      from: ACTIVITY_STATE__SUSPENDED,
      to: ACTIVITY_STATE__SUSPENDED,
      actionType: ACTIVITY_ACTION__COMPLETE_STEP,
      payload: COMPLETION_PROGRESS,
    },
    {
      from: ACTIVITY_STATE__SUSPENDED,
      to: ACTIVITY_STATE__ACTIVE,
      actionType: ACTIVITY_ACTION__RESUME,
      payload: SCHEDULE_PROPERTIES,
    },
    {
      from: ACTIVITY_STATE__SUSPENDED,
      to: ACTIVITY_STATE__ABORTED,
      actionType: ACTIVITY_ACTION__ABORT,
    },
    {
      from: ACTIVITY_STATE__SUSPENDED,
      to: ACTIVITY_STATE__COMPLETED,
      actionType: ACTIVITY_ACTION__COMPLETE,
      payload: COMPLETION_PROGRESS,
    },
    // EXPIRED -> EXPIRED (complete_step)
    // EXPIRED -> COMPLETED (complete)
    // EXPIRED -> ABORTED (abort)
    // EXPIRED -> ACTIVE (restart)
    {
      from: ACTIVITY_STATE__EXPIRED,
      to: ACTIVITY_STATE__EXPIRED,
      actionType: ACTIVITY_ACTION__COMPLETE_STEP,
      payload: COMPLETION_PROGRESS,
    },
    {
      // NOTE: It does not make sense to distinguish between "complete" and "complete expired",
      //       because if some different business logic was used, then one can always bypass it
      //       via EXPIRED -> ACTIVE -> COMPLETED. So it seems like it would not introduce any
      //       additional value.
      from: ACTIVITY_STATE__EXPIRED,
      to: ACTIVITY_STATE__COMPLETED,
      actionType: ACTIVITY_ACTION__COMPLETE,
      payload: COMPLETION_PROGRESS,
    },
    {
      from: ACTIVITY_STATE__EXPIRED,
      to: ACTIVITY_STATE__ABORTED,
      actionType: ACTIVITY_ACTION__ABORT,
    },
    {
      from: ACTIVITY_STATE__EXPIRED,
      to: ACTIVITY_STATE__ACTIVE,
      actionType: ACTIVITY_ACTION__RESTART,
      payload: ['dateEnd', 'timeEnd'],
    },
  ],
};
