import isPlainObject from 'lodash/isPlainObject';
import { createSelector } from 'reselect';
import { QUESTION_TYPE__EMPTY } from '../common/constants';
import Questionnaire from '../common/models/Questionnaire';
import QuestionnaireTranslation from '../common/models/QuestionnaireTranslation';
import toSelector from '../common/utils/toSelector';

export const addFinishQuestion = (questionnaire) => ({
  ...questionnaire,
  questions: [
    ...(questionnaire.questions || []),
    {
      id: '~finish',
      type: QUESTION_TYPE__EMPTY,
      sectionId: questionnaire.rootSectionId,
    },
  ],
});

export const isFinish = (question) => question.id === '~finish';

const parseQuestionnaire = (selectRawQuestionnaire, selectRawTranslation) =>
  createSelector(
    toSelector(selectRawQuestionnaire),
    toSelector(selectRawTranslation),
    (rawQuestionnaire, rawTranslation) => {
      let questionnaireObject;
      let questionnaire;
      if (isPlainObject(rawQuestionnaire)) {
        questionnaireObject = rawQuestionnaire;
      } else if (typeof rawQuestionnaire === 'string') {
        try {
          questionnaireObject = JSON.parse(rawQuestionnaire);
        } catch (err) {
          // ...
        }
      }
      if (questionnaireObject) {
        questionnaire = new Questionnaire(
          addFinishQuestion(questionnaireObject),
        );
      } else {
        questionnaire = new Questionnaire({});
      }
      let translationObject;
      if (isPlainObject(rawTranslation)) {
        translationObject = rawTranslation;
      } else if (typeof rawTranslation === 'string') {
        try {
          translationObject = JSON.parse(rawTranslation);
        } catch (err) {
          // ...
        }
      }
      if (translationObject) {
        const translation = new QuestionnaireTranslation(translationObject);
        questionnaire = translation.translate(questionnaire);
      }
      return questionnaire;
    },
  );

export default parseQuestionnaire;
