import compact from 'lodash/compact';
import mapValues from 'lodash/mapValues';
import { createSelector } from 'reselect';
import createGetAtKey from '../../utilsClient/createGetAtKey';
import { constant } from '../../utilsClient/selectors';
import toSelector from '../../utils/toSelector';
import { createDynamicQuestionnaireSelectors } from './selectors';
import { RESPONSE_SOURCE__USER_INPUT } from '../../constants';
import {
  ACTION_SET_VALUE,
  ACTION_INSERT_ELEMENT,
  ACTION_REMOVE_ELEMENT,
  ACTION_MOVE_ELEMENT_UP,
  ACTION_MOVE_ELEMENT_DOWN,
  ACTION_VALIDATE_QUESTIONNAIRE,
  ACTION_REPLACE_FORM_ERRORS,
  ACTION_FORCE_AUTOSAVE,
  ACTION_SYNC_FORM_VALUES,
  ACTION_SKIP_ANSWER,
} from './actions';

const selectState = (state) => state.questionnaire;

const createValuesSelector = (selectName, field) =>
  createSelector(
    selectState,
    selectName,
    (state, name) => state && state[name] && state[name][field],
  );

class SelectorsHub {
  constructor(props) {
    const {
      name,
      questionnaire,
      properties,
      variables,
      sortedBy,
      flatSections,
      answersSheetId,
      sessionId,
      debounceEdit,
      disabled,
    } = props;

    const selectName = toSelector(name);
    const selectQuestionnaire = toSelector(questionnaire);
    const selectPropertiesOverrides = toSelector(properties);
    const selectSortedBy = toSelector(sortedBy);
    const selectFlatSections = toSelector(flatSections);
    const selectVariables = toSelector(variables);
    const selectAnswersSheetId = toSelector(answersSheetId);
    const selectSessionId = toSelector(sessionId);

    const selectRawFormValues = createValuesSelector(selectName, 'values');
    const selectRawTouched = createValuesSelector(selectName, 'touched');
    const selectValidationErrors = createValuesSelector(selectName, 'errors');

    this.select = createDynamicQuestionnaireSelectors({
      selectRawFormValues,
      selectPropertiesOverrides,
      selectRawTouched,
      selectVariables,
      selectValidationErrors,
      selectQuestionnaire,
      selectSortedBy,
      selectFlatSections,
    });

    this.select.context = constant(constant(this));
    this.select.name = constant(selectName);
    this.select.options = constant(
      toSelector({
        debounceEdit,
        disabled,
      }),
    );

    this.select.meta = constant(
      toSelector({
        name: selectName,
        rawQuestionnaire: createSelector(
          selectQuestionnaire,
          (doc) => doc && doc.raw,
        ),
        variables: selectVariables,
        properties: selectPropertiesOverrides,
        sessionId: selectSessionId,
        answersSheetId: selectAnswersSheetId,
      }),
    );
  }

  getBasePath(path) {
    return compact([this.scopeKey, this.questionId, path]).join('.');
  }

  getScopeKey(elementId) {
    if (this.scopeKey) {
      return `${this.scopeKey}.${this.questionId}._elements.${elementId}._elements`;
    }
    return `${this.questionId}._elements.${elementId}._elements`;
  }

  createPropertiesSelector(questionId = this.questionId) {
    const key = compact([this.scopeKey, questionId]).join('.');
    return createSelector(this.select.dynamicProperties(), createGetAtKey(key));
  }

  createFormValueSelector(name = 'value') {
    const key = this.getBasePath(name);
    return createSelector(this.select.formValues(), createGetAtKey(key));
  }

  createValidationErrorSelector(name = 'value') {
    const key = this.getBasePath(name);
    return createSelector(this.select.validationErrors(), createGetAtKey(key));
  }

  createTouchedSelector(name = 'value') {
    const key = this.getBasePath(name);
    return createSelector(this.select.touched(), createGetAtKey(key));
  }

  createSubContext(elementId) {
    const newContext = Object.assign(Object.create(this), {
      question: null,
      questionId: null,
      scopeKey: this.getScopeKey(elementId),
    });
    return newContext;
  }

  bindProps(props) {
    const newSelectors = mapValues(
      this.select,
      (selectorCreator) =>
        (...args) => {
          const selector = selectorCreator(...args);
          return (state) => selector(state, props);
        },
    );
    const newContext = Object.assign(Object.create(this), {
      select: newSelectors,
    });
    return newContext;
  }

  setValue(field, value, source = RESPONSE_SOURCE__USER_INPUT) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        type: ACTION_SET_VALUE,
        payload: value,
        meta: {
          ...meta,
          field,
          key: this.getBasePath(),
          source,
          ts: Date.now(),
        },
      });
    };
  }

  skipAnswer({ source = RESPONSE_SOURCE__USER_INPUT } = {}) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        type: ACTION_SKIP_ANSWER,
        // NOTE: At a later stage we may decide to use other values
        //       based on current question type, e.g. [], or "".
        payload: null,
        meta: {
          ...meta,
          key: this.getBasePath(),
          source,
          ts: Date.now(),
        },
      });
    };
  }

  insertElement(elementId, questionId) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        type: ACTION_INSERT_ELEMENT,
        payload: elementId,
        meta: {
          ...meta,
          key: this.getBasePath(questionId),
        },
      });
    };
  }

  removeElement(elementId, questionId) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        type: ACTION_REMOVE_ELEMENT,
        payload: elementId,
        meta: {
          ...meta,
          key: this.getBasePath(questionId),
        },
      });
    };
  }

  moveElementUp(elementId, questionId) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        type: ACTION_MOVE_ELEMENT_UP,
        payload: elementId,
        meta: {
          ...meta,
          key: this.getBasePath(questionId),
        },
      });
    };
  }

  moveElementDown(elementId, questionId) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        type: ACTION_MOVE_ELEMENT_DOWN,
        payload: elementId,
        meta: {
          ...meta,
          key: this.getBasePath(questionId),
        },
      });
    };
  }

  validate(dryRun = false) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        meta: {
          ...meta,
          dryRun,
        },
        type: ACTION_VALIDATE_QUESTIONNAIRE,
      });
    };
  }

  setErrors(payload) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        meta,
        payload,
        type: ACTION_REPLACE_FORM_ERRORS,
      });
    };
  }

  forceAutosave() {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        meta,
        type: ACTION_FORCE_AUTOSAVE,
      });
    };
  }

  syncFormValues(payload) {
    return (dispatch, getState) => {
      const state = getState();
      const meta = this.select.meta()(state);
      return dispatch({
        meta,
        payload,
        type: ACTION_SYNC_FORM_VALUES,
      });
    };
  }
}

export default SelectorsHub;
