import map from 'lodash/map';
import max from 'lodash/max';
import forEach from 'lodash/forEach';
import lodashValues from 'lodash/values';
import isPlainObject from 'lodash/isPlainObject';
import createDefaultLogger from './createDefaultLogger';
import { levels, valueToLevel } from './constants';

export class Logger {
  constructor({
    topic = 'logs',
    level = 'info',
    maxLevel = 'trace',
    parent,
  } = {}) {
    this.topic = topic;
    this.maxValue = levels[maxLevel];
    this.parent = parent;
    this.loggers = {};
    this.setLevel(level);
  }

  create(topic, options = {}) {
    const { maxLevel } = options;
    if (!topic) {
      throw new Error('Creating child logger requires a topic');
    }
    if (this.loggers[topic]) {
      throw new Error(`Logger for topic "${topic}" already exists`);
    }
    const logger = new this.constructor({
      topic,
      level: this.getLevelFor(topic),
      maxLevel,
      parent: this,
    });
    this.loggers[topic] = logger;
    return logger;
  }

  log(options) {
    const { level, message, ...other } = options;
    this[level](message, other);
  }

  getLevelFor(topic) {
    if (typeof this.level === 'string') {
      return this.level;
    }
    if (isPlainObject(this.level)) {
      if (this.level[topic]) {
        return this.level[topic];
      }
      if (this.level['*']) {
        return this.level['*'];
      }
      return valueToLevel[this.value];
    }
    return this.level;
  }

  setLevel(level) {
    if (typeof level === 'string') {
      this.level = level;
      this.value = levels[level];
    } else if (isPlainObject(level)) {
      const value = max(map(lodashValues(level), (l) => levels[l]));
      this.level = level;
      this.value = value;
    } else {
      throw Error(`Unknown log level ${level}`);
    }
    forEach(this.loggers, (logger, topic) => {
      logger.setLevel(this.getLevelFor(topic));
    });
  }

  setProxy(logger) {
    if (!this.parent) {
      this.proxy = logger;
    } else {
      this.warn(
        'Setting logger proxy does not make sense if parent is already set',
      );
    }
  }
}

forEach(levels, (value, level) => {
  Logger.prototype[level] = function (message, meta) {
    if (value <= this.value && value <= this.maxValue) {
      const newMeta = {
        ...meta,
        topic:
          meta && meta.topic ? `${this.topic}.${meta.topic}` : `${this.topic}`,
      };
      (this.parent || this.proxy)[level](message, newMeta);
    }
  };
});

const logger = new Logger();
logger.setProxy(createDefaultLogger());

export default logger;
