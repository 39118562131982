import Id from '../../schemata/Id';

// INITIAL is a formal state, and I don't currently see any practical uses for it.
export const PARTICIPATION_STATE__INITIAL = 'INITIAL';
// A participation is planned if the patient is considered for project enrollment
// but it's not yet clear if they will be enrolled or not, e.g. certain criteria needs
// to be met, or patient needs to give consent.
export const PARTICIPATION_STATE__PLANNED = 'PLANNED';
// The patient has been enrolled to project and his participation is considered "ongoing".
// Please note that the trackDate does not necessarily need to be known immediately
// and can be adjusted later on.
export const PARTICIPATION_STATE__ACTIVE = 'ACTIVE';
// The participation has to be "paused" for some reasons. For example, the patient
// has some difficulties responding to questionnaires - either technical or poor health condition.
export const PARTICIPATION_STATE__SUSPENDED = 'SUSPENDED';
// The participation was active but it was eventually aborted before it was considered
// "ENDED". This is sometimes also called "early discharged".
export const PARTICIPATION_STATE__TERMINATED = 'TERMINATED';
// The participation was planned but it never started, e.g. because some preconditions were not met.
export const PARTICIPATION_STATE__CANCELED = 'CANCELED';
// The participation is considered "ENDED". It does not necessarily mean the same
// as completion of all milestones, because patient might have missed some of them or
// the had a completely custom path.
export const PARTICIPATION_STATE__ENDED = 'ENDED';

export const PARTICIPATION_STATES = [
  PARTICIPATION_STATE__INITIAL,
  PARTICIPATION_STATE__PLANNED,
  PARTICIPATION_STATE__ACTIVE,
  PARTICIPATION_STATE__SUSPENDED,
  PARTICIPATION_STATE__TERMINATED,
  PARTICIPATION_STATE__CANCELED,
  PARTICIPATION_STATE__ENDED,
];

export const PARTICIPATION_FINAL_STATES = [
  PARTICIPATION_STATE__TERMINATED,
  PARTICIPATION_STATE__CANCELED,
  PARTICIPATION_STATE__ENDED,
];

export const PARTICIPATION_STATES_AFTER_START = [
  PARTICIPATION_STATE__ACTIVE,
  PARTICIPATION_STATE__SUSPENDED,
  PARTICIPATION_STATE__TERMINATED,
  PARTICIPATION_STATE__ENDED,
];

export const PARTICIPATION_ACTION__PLAN = 'plan';
export const PARTICIPATION_ACTION__START = 'start';
export const PARTICIPATION_ACTION__CANCEL = 'cancel';
export const PARTICIPATION_ACTION__SUSPEND = 'suspend';
export const PARTICIPATION_ACTION__TERMINATE = 'terminate';
export const PARTICIPATION_ACTION__RESUME = 'resume';
export const PARTICIPATION_ACTION__END = 'end';
export const PARTICIPATION_ACTION__CHANGE_TRACK = 'change_track';
export const PARTICIPATION_ACTION__CONFIGURE = 'configure';
export const PARTICIPATION_ACTION__RESCHEDULE = 'reschedule';

export const PARTICIPATION_ACTIONS = [
  PARTICIPATION_ACTION__PLAN,
  PARTICIPATION_ACTION__START,
  PARTICIPATION_ACTION__CANCEL,
  PARTICIPATION_ACTION__SUSPEND,
  PARTICIPATION_ACTION__TERMINATE,
  PARTICIPATION_ACTION__RESUME,
  PARTICIPATION_ACTION__END,
  PARTICIPATION_ACTION__CHANGE_TRACK,
  PARTICIPATION_ACTION__CONFIGURE,
  PARTICIPATION_ACTION__RESCHEDULE,
];

const TRACK_PROPERTIES = ['trackId', 'trackDate'];

export const PARTICIPATION_STATE_MACHINE = {
  modelName: 'Participation',
  coordinates: [
    {
      name: 'state',
      type: 'string',
    },
    {
      name: 'trackId',
      type: 'string',
      pattern: Id.pattern,
    },
    {
      name: 'trackDate',
      type: 'string',
      format: 'full-date',
    },
  ],
  states: [
    {
      state: PARTICIPATION_STATE__INITIAL,
    },
    {
      state: PARTICIPATION_STATE__PLANNED,
    },
    {
      state: PARTICIPATION_STATE__ACTIVE,
    },
    {
      state: PARTICIPATION_STATE__SUSPENDED,
    },
    {
      state: PARTICIPATION_STATE__CANCELED,
    },
    {
      state: PARTICIPATION_STATE__TERMINATED,
    },
    {
      state: PARTICIPATION_STATE__ENDED,
    },
  ],
  transitions: [
    // INITIAL -> PLANNED (plan)
    // INITIAL -> SCHEDULED (schedule)
    // INITIAL -> ACTIVE (start)
    {
      from: PARTICIPATION_STATE__INITIAL,
      to: PARTICIPATION_STATE__PLANNED,
      actionType: PARTICIPATION_ACTION__PLAN,
      payload: TRACK_PROPERTIES,
    },
    {
      from: PARTICIPATION_STATE__INITIAL,
      to: PARTICIPATION_STATE__ACTIVE,
      actionType: PARTICIPATION_ACTION__START,
      payload: TRACK_PROPERTIES,
    },
    // PLANNED -> SCHEDULED (schedule)
    // PLANNED -> ACTIVE (start)
    // PLANNED -> CANCELED (canceled)
    // PLANNED -> PLANNED (reconfigure)
    {
      from: PARTICIPATION_STATE__PLANNED,
      to: PARTICIPATION_STATE__ACTIVE,
      actionType: PARTICIPATION_ACTION__START,
      payload: TRACK_PROPERTIES,
    },
    {
      from: PARTICIPATION_STATE__PLANNED,
      to: PARTICIPATION_STATE__CANCELED,
      actionType: PARTICIPATION_ACTION__CANCEL,
    },
    {
      from: PARTICIPATION_STATE__PLANNED,
      to: PARTICIPATION_STATE__PLANNED,
      actionType: PARTICIPATION_ACTION__CONFIGURE,
      payload: TRACK_PROPERTIES,
    },
    // ACTIVE -> ACTIVE (reschedule)
    // ACTIVE -> ACTIVE (change_track)
    // ACTIVE -> SUSPENDED (suspend)
    // ACTIVE -> TERMINATED (terminate)
    // ACTIVE -> ENDED (end)
    {
      // NOTE: Because this action comes before "change_track", it will be
      //       always preferred if there's no change to trackId. This means
      //       that trackDate can potentially be modified without
      //       changing track. This can be useful for rescheduling activities
      //       that were already created.
      from: PARTICIPATION_STATE__ACTIVE,
      to: PARTICIPATION_STATE__ACTIVE,
      actionType: PARTICIPATION_ACTION__RESCHEDULE,
      payload: ['trackDate'],
    },
    {
      from: PARTICIPATION_STATE__ACTIVE,
      to: PARTICIPATION_STATE__ACTIVE,
      actionType: PARTICIPATION_ACTION__CHANGE_TRACK,
      payload: TRACK_PROPERTIES,
    },
    // TODO: Temporarily disable this state transition because
    //       the corresponding hooks are not implemented yet.
    // {
    //   from: PARTICIPATION_STATE__ACTIVE,
    //   to: PARTICIPATION_STATE__SUSPENDED,
    //   actionType: PARTICIPATION_ACTION__SUSPEND,
    // },
    {
      from: PARTICIPATION_STATE__ACTIVE,
      to: PARTICIPATION_STATE__TERMINATED,
      actionType: PARTICIPATION_ACTION__TERMINATE,
    },
    {
      from: PARTICIPATION_STATE__ACTIVE,
      to: PARTICIPATION_STATE__ENDED,
      actionType: PARTICIPATION_ACTION__END,
    },
    // SUSPENDED -> ACTIVE (resume)
    // SUSPENDED -> TERMINATED (terminated)
    // SUSPENDED -> ENDED (end)
    {
      from: PARTICIPATION_STATE__SUSPENDED,
      to: PARTICIPATION_STATE__ACTIVE,
      actionType: PARTICIPATION_ACTION__RESUME,
    },
    {
      from: PARTICIPATION_STATE__SUSPENDED,
      to: PARTICIPATION_STATE__TERMINATED,
      actionType: PARTICIPATION_ACTION__TERMINATE,
    },
    {
      from: PARTICIPATION_STATE__SUSPENDED,
      to: PARTICIPATION_STATE__ENDED,
      actionType: PARTICIPATION_ACTION__END,
    },
  ],
};
