import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import ThemeProviderCommon from '../common/components/ThemeProvider';
import preferences from '../store/preferences';
import theme from '../ui/theme';

const ThemeProvider = ({ children }) => {
  const globalStateTheme = useSelector(preferences.get('theme'));

  useEffect(() => {
    if (
      globalStateTheme === 'dark' ||
      (!globalStateTheme &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [globalStateTheme]);

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <ThemeProviderCommon globalStateTheme={globalStateTheme}>
        {children}
      </ThemeProviderCommon>
    </StyledComponentsThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
